import React, { useState, useEffect } from "react";
import Contact from "./components/contact/Contact";
import Experience from "./components/experience/Experience";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Intro from "./components/intro/Intro";
import Topbar from "./components/topbar/Topbar";
import LoadingBar from "react-top-loading-bar";

const App = () => {
  const [loadingBarProgress, setLoadingBarProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingBarProgress((prevProgress) => {
        if (prevProgress < 100) {
          return prevProgress + 10;
        } else {
          clearInterval(interval);
          return 0;
        }
      });
    }, 50);

    return () => clearInterval(interval);
  }, []);

  const onLoaderFinished = () => {};

  return (
    <div className="app-container">
      <LoadingBar
        progress={loadingBarProgress}
        height={3}
        color="#0077B5"
        onLoaderFinished={onLoaderFinished}
      />

      <Header />
      <Topbar />
      <Intro />
      <Experience />
      <Contact />
      <Footer />
    </div>
  );
};

export default App;
